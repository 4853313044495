import { Alert, Card } from "react-bootstrap";
import AuthenticationStatus from "../../enums/AuthenticationStatus";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Authenticated from "./Authenticated";
import AuthenticateLanding from "./landing/AuthenticateLanding";
import { Route, Routes } from "react-router-dom";
import EmailLogin from "./email/EmailLogin";
import { setAuthMethod } from "../Authentication";
import { AuthenticationMethod } from "../../enums/AuthenticationMethod";

export default function Login() {
    const { instance, accounts, inProgress } = useMsal();
    const { t } = useTranslation();

    const [status, setStatus] = useState<AuthenticationStatus>(AuthenticationStatus.UNAUTHENTICATED);

    useEffect(() => {
        const initializeMsal = async () => {
            if (!instance.getAllAccounts().length) {
                await instance.initialize();
            }

            if (["login", "acquireToken", "handleRedirect"].includes(inProgress)) {
                setStatus(AuthenticationStatus.AUTHENTICATION_IN_PROGRESS);
            } else if (accounts.length > 0) {
                setStatus(AuthenticationStatus.AUTHENTICATION_SUCCESSFUL);
            }

            try {
                const response = await instance.handleRedirectPromise();
                if (response && response.account) {
                    instance.setActiveAccount(response.account);
                    setAuthMethod(AuthenticationMethod.MSAL);
                }
            } catch (e) {
                console.error(e)
            }
        };

        initializeMsal();
    }, [instance, accounts, inProgress]);

    return (
        <>
            <Card.Body>
                {
                    {
                        [AuthenticationStatus.AUTHENTICATION_SUCCESSFUL]: <Authenticated />,
                        [AuthenticationStatus.AUTHENTICATION_ERROR]: <Alert variant="danger">{t('authentication.authorise.error')}</Alert>,
                        [AuthenticationStatus.AUTHENTICATION_IN_PROGRESS]: <Alert variant="info">{t('general.pleaseWait')}</Alert>,
                        [AuthenticationStatus.UNAUTHENTICATED]:
                            <Routes>
                                <Route path='/login/other' element={<EmailLogin />} />
                                <Route path='*' element={<AuthenticateLanding setStatus={setStatus} />}/>
                            </Routes>
                    }[status]
                }
            </Card.Body>
        </>
    );
}