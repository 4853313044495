import FormFieldValidation from "../../../enums/FormFieldValidation";

export enum AuthenticateFormFieldValidation {
    
    /**
     * @description The field is an email address and not a supported domain
     */
    UNSUPPORTED_EMAIL_DOMAIN = 'UNSUPPORTED_EMAIL_DOMAIN',
}

export interface ILoginValidation {
    displayName: FormFieldValidation;
    emailAddress: FormFieldValidation | AuthenticateFormFieldValidation;
    otp: FormFieldValidation;
}

const validate = (name: string, email: string, showOtp: boolean, otp?: string): ILoginValidation => {
    const emailValidation = email.length > 0
        ? !email.endsWith("@macro-group.com") && !email.endsWith("@consultdolphin.com")
            ? FormFieldValidation.VALID
            : AuthenticateFormFieldValidation.UNSUPPORTED_EMAIL_DOMAIN
        : FormFieldValidation.INVALID;
    
    const nameValidation = name.length > 0
        ? FormFieldValidation.VALID
        : FormFieldValidation.INVALID;

    const otpValidation = showOtp
        ? otp?.length === 6
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID
        : FormFieldValidation.VALID;

    return {
        displayName: nameValidation,
        emailAddress: emailValidation,
        otp: otpValidation
    };
}

const newValidation = (): ILoginValidation => ({
    displayName: FormFieldValidation.VALID,
    emailAddress: FormFieldValidation.VALID,
    otp: FormFieldValidation.VALID
});

export { validate, newValidation };